import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me_new.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
      <section className="container header__container">
        <h5>Hey, I'm</h5>
        <h1>Sarthak Mishra</h1>
        <h5 className="text-light">I enjoy developing websites and products.</h5>
        <CTA />
        <HeaderSocials />
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <a href="#contact" className="scroll__down">Scroll Down</a>
      </section>
  )
}

export default Header