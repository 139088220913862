import React from 'react'
import './nav.css'
import { AiFillHome } from 'react-icons/ai'
import { AiOutlineUser } from 'react-icons/ai'
import { BiBookContent } from 'react-icons/bi'
import { BiSolidContact } from 'react-icons/bi'
import { BsPersonGear } from "react-icons/bs";
import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#")
  return (
    <nav>
      <a href="#" onClick={() => { setActiveNav('#')}} className={activeNav == '#' ? 'active': ''}><AiFillHome /></a>
      <a href="#about" onClick={() => { setActiveNav('#about')}} className={activeNav == '#about' ? 'active': ''}><AiOutlineUser /></a>
      <a href="#experience" onClick={() => { setActiveNav('#experience')}} className={activeNav == '#experience' ? 'active': ''}><BiBookContent /></a>
      <a href="#portfolio" onClick={() => { setActiveNav('#portfolio')}} className={activeNav == '#about' ? 'active': ''}><BsPersonGear /></a>
      <a href="#contact" onClick={() => { setActiveNav('#contact')}} className={activeNav == '#contact' ? 'active': ''}><BiSolidContact /></a>
    </nav>
  )
}

export default Nav