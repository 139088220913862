import React,{ useRef } from 'react'
import './contact.css'
import { AiOutlineMail } from 'react-icons/ai'
import { BiMessageAltDetail } from 'react-icons/bi'
import { BsFillSendFill } from 'react-icons/bs'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_r4dmm4f', 'template_1ihdt3m', form.current, 'vaL8cHuXKF-drh1d9')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>mishra.sarthak93@gmail.com</h5>
            <a href="mailto:mishra.sarthak93@gmail.com" target='_blank'>Send a Mail</a>
          </article>
          <article className="contact__option">
            <BiMessageAltDetail className='contact__option-icon' />
            <h4>Phone</h4>
            <h5>+1 (530)-630-7581</h5>
            <a href="sms:+15306307581" target='_blank'>Shoot a text</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="name" name="name" placeholder='Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" placeholder='Your Message' rows="7"></textarea>
          <button className='btn btn-primary'><BsFillSendFill /></button>
        </form>
      </div>
    </section>
  )
}

export default Contact