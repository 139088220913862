import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/p1.png";
import IMG2 from "../../assets/p2.jpg";
import IMG3 from "../../assets/how-to-write-a-blog-post.svg";
import IMG4 from "../../assets/figma.jpg";
import IMG5 from "../../assets/weather-app.png"
import IMG6 from "../../assets/event-booking.jpg"

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>Inventory Mangement System</h3>
          <h5>(Academic)</h5>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/xl26/CSCI620_FE"
              target="_blank"
              className="btn"
            >
              Github
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <div className="portfolio__item-image">
              <img src={IMG2} alt="" />
            </div>
          </div>
          <h3>Pantry Management</h3>
          <h5>(Academic)</h5>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/ChicoState/PantryNode"
              target="_blank"
              className="btn"
            >
              Github
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <div className="portfolio__item-image">
              <img src={IMG3} alt="" />
            </div>
          </div>
          <h3>Next Js BlogPost App</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/xl26/next-blog/tree/master"
              target="_blank"
              className="btn"
            >
              Github
            </a>
            <a
              href="https://next-blog-sable-beta.vercel.app/"
              target="_blank"
              className="btn btn-primary"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <div className="portfolio__item-image">
              <img src={IMG5} alt="" />
            </div>
            <h3>React-Native Weather App</h3>
            <div className="portfolio__item-cta">
              <a
                href="https://github.com/xl26/weather-app"
                target="_blank"
                className="btn"
              >
                React-Native App
              </a>
            </div>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <div className="portfolio__item-image">
              <img src={IMG6} alt="" />
            </div>
            <h3>GraphQL-React Event Booking</h3>
            <div className="portfolio__item-cta">
              <a
                href="https://github.com/xl26/GraphQL-React-Event-Booking-App"
                target="_blank"
                className="btn"
              >
                Github
              </a>
            </div>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <div className="portfolio__item-image">
              <img src={IMG4} alt="" />
            </div>
            <h3>Figma-Wallet App Designs</h3>
            <div className="portfolio__item-cta">
              <a
                href="https://www.figma.com/file/mzKn1KAzLcvuqMlCT7fLJL/M-Wallet?type=design&t=nCeWvuByosuOdmKZ-6"
                target="_blank"
                className="btn"
              >
                Figma
              </a>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
