import React from 'react'
import './about.css'
import ME from '../../assets/me_about.png'
import { TbAward } from 'react-icons/tb'
import { CgOrganisation } from 'react-icons/cg'
import { GoProjectRoadmap } from 'react-icons/go'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
           <div className="about__cards">
            <article className='about__card'>
              <TbAward className='about__icon' />
              <h5>Experience</h5>
              <small>7 years</small>
            </article>
            <article className='about__card'>
              <CgOrganisation className='about__icon' />
              <h5>Companies</h5>
              <small>3+</small>
            </article>
            <article className='about__card'>
              <GoProjectRoadmap className='about__icon' />
              <h5>Projects</h5>
              <small>10+</small>
            </article>
           </div>
           <p>
           Even the world's best coders once began with a simple 'Hello World'. Greetings, I'm Sarthak, a seasoned full stack web developer with over 5 years of experience and Masters in Computer Science. Throughout my career, I've collaborated with various organizations on numerous successful projects. My unwavering passion lies in web development, as I firmly believe that the process of learning coding is never-ending. It's an expansive universe, and we are mere specks within it.

My ability to swiftly acquire knowledge has greatly contributed to my professional growth and scalability. I've always held the belief that the greatest achievements are realized when a group of like-minded individuals, driven by a common purpose, work together. Let's embark on this coding journey together and create something extraordinary
           </p>
           <a href="#contact" className='btn btn-primary'>Send me a mail</a>
        </div>
      </div>
    </section>
  )
}

export default About