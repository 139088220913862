import React from "react";
import "./footer.css";
import { FaLinkedin } from "react-icons/fa";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
} from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <a href="@" className="footer__logo">
        Sarthak
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Skills</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/mishrasarthak93/">
          <AiFillInstagram />
        </a>
        <a href="https://www.facebook.com/sarthak.mishra01/">
          <AiFillFacebook />
        </a>
        <a href="https://twitter.com/MishraSarthak93">
          <AiFillTwitterCircle />
        </a>
        <a href="https://www.instagram.com/bababhakt/">
          <AiFillInstagram />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Sarthak Mishra. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
